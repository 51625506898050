import { shopAdapter } from '@adapters/shopAdapter'
import { Content } from '@components/Content/Content'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { IShop } from '@interfaces/entities/shop/shop'
import { RoutePaths } from '@interfaces/routePaths'
import { Badge, Button, CardSection, Group, Text, Title } from '@mantine/core'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { StatusEnum } from '@nabthisdev/nabthis-types'
import {
  selectShops,
  selectShopsError,
  selectShopsIsLoading,
} from '@redux/shop/shopSlice'
import { selectActiveShop } from '@redux/site/siteSlice'
import { EntityState } from '@reduxjs/toolkit'
import { IconPlus } from '@tabler/icons-react'
import { Outlet, useNavigate } from 'react-router-dom'

export const ShopsList = (): JSX.Element => {
  const navigate = useNavigate()
  const activeShop = useAppSelector(selectActiveShop)
  const allShops = useAppSelector(selectShops)
  const isLoading = useAppSelector(selectShopsIsLoading)
  const error = useAppSelector(selectShopsError)

  const shops =
    !isLoading && !error
      ? shopAdapter.getSelectors().selectAll(allShops as EntityState<IShop>) ??
        []
      : []

  const handleNavigate = () => navigate(RoutePaths.SHOPS_CREATE)

  const handleClick = (shopId: string) =>
    navigate(`../${RoutePaths.SHOPS_PROFILE}/${shopId}`)

  return (
    <Content>
      <>
        {shops && shops.length > 0 ? (
          <Section
            title={
              <Title order={5}>
                Shops{' '}
                <Text color="gray.6" fw="normal" span>
                  ({shops.length})
                </Text>
              </Title>
            }
            rightSection={
              <Button
                leftIcon={<IconPlus size="0.8rem" />}
                onClick={handleNavigate}
              >
                New Shop Profile
              </Button>
            }
          >
            {shops.map((shop: Shop, i: number) => (
              <CardSection
                key={shop.id}
                p="md"
                onClick={() => handleClick(shop.id)}
                withBorder={i !== 0}
                sx={(theme) => ({
                  cursor: 'pointer',
                  '&:hover': {
                    '[data-shop]': {
                      color: theme.colors.violet,
                    },
                  },
                })}
              >
                <Group position="apart">
                  <Text fw="bold" size="sm" color="gray.6" data-shop>
                    {shop.name}
                  </Text>
                  <Group>
                    {activeShop && activeShop.id === shop.id && (
                      <Badge color="pink">Current</Badge>
                    )}
                    {shop.status === StatusEnum.ACTIVE ? (
                      <Badge color="green">Active</Badge>
                    ) : (
                      <Badge color="gray">Inactive</Badge>
                    )}
                  </Group>
                </Group>
              </CardSection>
            ))}
          </Section>
        ) : (
          <>
            <EmptyState type="shops" createPath={RoutePaths.SHOPS_CREATE} />
          </>
        )}
      </>

      <Outlet />
    </Content>
  )
}
