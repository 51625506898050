import * as Yup from 'yup'

export const createShopSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  addressLine1: Yup.string().required('Address is required'),
  addressLine2: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zipcode is required'),
  website: Yup.string().required('Website is required'),
  description: Yup.string().required('Description is required'),
  hours: Yup.array()
    .of(
      Yup.object({
        day: Yup.string(),
        open: Yup.string(),
        close: Yup.string(),
      }),
    )
    .optional(),
})

export type CreateShopState = Yup.InferType<typeof createShopSchema>

export const createShopInitialState: () => CreateShopState = () => ({
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: '',
  website: '',
  description: '',
  hours: [{ day: '', open: '', close: '' }],
})
