import { RoutePaths } from '@interfaces/routePaths'
import { Base as AdminBase } from '@views/Admin/Base'
import { Base as BrandsBase } from '@views/Admin/brands/Base'
import { Details as BrandDetails } from '@views/Admin/brands/Details'
import { List as BrandsList } from '@views/Admin/brands/List'
import { Manage as BrandsManage } from '@views/Admin/brands/Manage'
import { Invite } from '@views/Admin/Invite'
import { Overview } from '@views/Admin/Overview'
import { Base as ProductsBase } from '@views/Admin/products/Base'
import { Details as ProductDetails } from '@views/Admin/products/Details'
import { List as ProductsList } from '@views/Admin/products/List'
import { Shops } from '@views/Admin/Shops'
import { Base as UsersBase } from '@views/Admin/users/Base'
import { Details as UserDetails } from '@views/Admin/users/Details'
import { List as UsersList } from '@views/Admin/users/List'
import { List as ShopsList } from '@views/Shops/List'
import { Manage as ShopsManage } from '@views/Shops/manage/Manage'
import { Details as ShopDetails } from '@views/Shops/profile/Details'
import { RouteObject } from 'react-router-dom'
import { allShopsLoader } from '../loaders/allShopsLoader'

export const ADMIN_PATHS: RouteObject[] = [
  {
    path: RoutePaths.ADMIN,
    element: <AdminBase />,
    children: [
      {
        path: RoutePaths.ADMIN_OVERVIEW,
        element: <Overview />,
      },
      {
        path: RoutePaths.ADMIN_SHOPS,
        element: <Shops />,
        loader: allShopsLoader,
        children: [
          {
            path: RoutePaths.SHOPS_LIST,
            element: <ShopsList />,
            children: [
              {
                path: RoutePaths.SHOPS_CREATE,
                element: <ShopsManage />,
              },
            ],
          },
          {
            path: `${RoutePaths.SHOPS_PROFILE}/:shopId`,
            element: <ShopDetails />,
            children: [
              {
                path: `${RoutePaths.SHOPS_MANAGE}/:mode`,
                element: <ShopsManage />,
              },
            ],
          },
        ],
      },
      //   {
      //     path: RoutePaths.ADMIN_INVITE,
      //     element: <Invite />,
      //   },
      {
        path: RoutePaths.ADMIN_BRANDS,
        element: <BrandsBase />,
        children: [
          {
            path: RoutePaths.ADMIN_BRANDS_LIST,
            element: <BrandsList />,
            children: [
              {
                path: RoutePaths.ADMIN_BRANDS_CREATE,
                element: <BrandsManage />,
              },
            ],
          },
          {
            path: `${RoutePaths.ADMIN_BRANDS_DETAILS}/:brandId`,
            element: <BrandDetails />,
            children: [
              {
                path: `${RoutePaths.ADMIN_BRANDS_MANAGE}/:mode`,
                element: <BrandsManage />,
              },
            ],
          },
        ],
      },
      {
        path: RoutePaths.ADMIN_ACCOUNTS,
        element: <UsersBase />,
        children: [
          {
            path: RoutePaths.ADMIN_ACCOUNTS_LIST,
            element: <UsersList />,
          },
          {
            path: `${RoutePaths.ADMIN_ACCOUNTS_DETAILS}/:accountId`,
            element: <UserDetails />,
          },
        ],
      },
      {
        path: RoutePaths.ADMIN_PRODUCTS,
        element: <ProductsBase />,
        children: [
          {
            path: RoutePaths.ADMIN_PRODUCTS_LIST,
            element: <ProductsList />,
          },
          {
            path: `${RoutePaths.ADMIN_PRODUCTS_DETAILS}/:productId`,
            element: <ProductDetails />,
          },
        ],
      },
    ],
  },
]
