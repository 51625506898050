import * as Yup from 'yup'

export const createThriftProductSchema = Yup.object({
  title: Yup.string().required('Name is required'),
  brandName: Yup.string().required('Brand is required'),
  sku: Yup.string().required('SKU is required'),
  permalink: Yup.string().required('Permalink is required'),
  category: Yup.string().required('Category is required'),
  subcategory: Yup.string().optional(),
  description: Yup.string().required('Description is required'),
  color: Yup.string().required('Color is required'),
  price: Yup.string().required('Price is required'),
  size: Yup.string().required('Size is required'),
  condition: Yup.string().required('Condition is required'),
  department: Yup.string().required('Department is required'),
  imperfections: Yup.string().optional(),
  measurements: Yup.string().optional(),
  photos: Yup.array().of(
    Yup.object({
      photo: Yup.mixed().optional().nullable(),
      caption: Yup.string().optional().nullable(),
    }),
  ),
})

export type CreateThriftProductState = Yup.InferType<
  typeof createThriftProductSchema
>

export const createThriftProductInitialState: () => CreateThriftProductState =
  () => ({
    title: '',
    brandName: '',
    sku: '',
    permalink: '',
    category: '',
    subcategory: '',
    description: '',
    color: '',
    price: '',
    size: '',
    condition: '',
    department: '',
    imperfections: '',
    measurements: '',
    photos: [
      {
        photo: null,
        caption: '',
      },
    ],
  })
