import { CACHE_PREFETCH } from '@constants/cache'
import { shopEndpoints } from '@redux/shop/shopEndpoints'
import { store } from '@redux/store'

export function allShopsPrefetch() {
  store.dispatch(
    shopEndpoints.util.prefetch('getAllShops', undefined, {
      ifOlderThan: CACHE_PREFETCH,
    }),
  )
}
