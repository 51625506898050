import { useAppSelector } from '@hooks/redux'
import { ProductCategoryEnum, ProductTypeEnum } from '@nabthisdev/nabthis-types'
import { useGetProductsQuery } from '@redux/product/productEndpoints'
import { selectActiveShop } from '@redux/site/siteSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { Outlet } from 'react-router'
import { List } from './List'

export const Vintage = (): JSX.Element => {
  const shop = useAppSelector(selectActiveShop)
  const { data: productEntityState, isLoading } = useGetProductsQuery(
    shop && shop.id
      ? {
          shopId: shop.id,
          type: ProductTypeEnum.VINTAGE,
        }
      : skipToken,
  )

  return (
    <>
      <List
        type={ProductTypeEnum.VINTAGE}
        productEntityState={productEntityState}
        isLoading={isLoading}
      />

      <Outlet
        context={{
          productEntityState,
          isLoading,
          type: ProductTypeEnum.VINTAGE,
        }}
      />
    </>
  )
}
