import { selectAccountInformation } from '@redux/account/accountSlice'
import { store } from '@redux/store'
import { getDefaultLink } from '@utils/getDefaultLink'
import { redirect } from 'react-router-dom'

export async function redirectLoader() {
  const state = store.getState()
  const account = selectAccountInformation(state)
  const defaultLink = getDefaultLink(account.role)

  return redirect(defaultLink)
}
