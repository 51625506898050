import { brandAdapter } from '@adapters/brandAdapter'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { IBrand } from '@interfaces/entities/brand/brand'
import { RoutePaths } from '@interfaces/routePaths'
import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useDeleteBrandMutation } from '@redux/brand/brandEndpoints'
import { EntityState } from '@reduxjs/toolkit'
import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

export const DeleteBrand = (): JSX.Element => {
  const navigate = useNavigate()
  const { brandId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [deleteBrand] = useDeleteBrandMutation()
  const {
    brandEntityState,
  }: {
    brandEntityState: EntityState<IBrand>
  } = useOutletContext()

  const brand =
    brandEntityState &&
    (brandAdapter
      .getSelectors()
      .selectById(brandEntityState, brandId) as IBrand)

  const handleClose = () => navigate(`../../${RoutePaths.ADMIN_BRANDS_LIST}`)

  const handleDelete = async () => {
    if (isSubmitting || !brandId) return

    setSubmitting(true)

    try {
      await deleteBrand(brandId).unwrap()

      showNotification({
        message: `${brand.name} deleted!`,
        color: 'green',
      })
    } catch (err) {
      showNotification({
        message: `Unable to delete ${brand.name}!`,
        color: 'red',
      })
    } finally {
      handleClose()
    }
  }

  return (
    <DestructiveConfirm
      confirmText="Delete"
      opened={true}
      onCancel={handleClose}
      onConfirm={handleDelete}
      isLoading={isSubmitting}
    >
      <Text>
        Are you sure you would like to delete the brand{' '}
        <strong>{brand?.name}</strong>?
      </Text>
    </DestructiveConfirm>
  )
}
