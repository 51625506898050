import { RoutePaths } from '@interfaces/routePaths'
import { Dashboard } from '@views/Dashboard/Dashboard'
import { RouteObject } from 'react-router-dom'
import { BasePath } from './BasePath'
import { defaultLoader } from './loaders/defaultLoader'
import { redirectLoader } from './loaders/redirectLoader'
// import { ACCOUNT_PATHS } from './paths/account'
import { ADMIN_PATHS } from './paths/admin'
import { AUTH_PATHS } from './paths/auth'
// import { POSTS_PATHS } from './paths/posts'
import { PRODUCTS_PATHS } from './paths/products'
import { SHOP_PATHS } from './paths/shops'
// import { WISHLIST_PATHS } from './paths/wishlists'
import { RouteNotFound } from './RouteNotFound'

export const ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <BasePath />,
    errorElement: <RouteNotFound />,
    loader: defaultLoader,
    children: [
      {
        path: '/',
        loader: redirectLoader,
      },
      {
        path: RoutePaths.DASHBOARD,
        element: <Dashboard />,
      },
      // ...ACCOUNT_PATHS,
      ...ADMIN_PATHS,
      ...SHOP_PATHS,
      ...PRODUCTS_PATHS,
      // ...WISHLIST_PATHS,
      // ...POSTS_PATHS,
    ],
  },
  ...AUTH_PATHS,
]
