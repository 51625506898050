import { selectAccountInformation } from '@redux/account/accountSlice'
import { selectIsLoggedIn } from '@redux/auth/authSlice'
import { store } from '@redux/store'
import { getDefaultLink } from '@utils/getDefaultLink'
import { redirect } from 'react-router-dom'

export async function publicGuard() {
  const state = store.getState()
  const isLoggedIn = selectIsLoggedIn(state)

  if (isLoggedIn) {
    const account = selectAccountInformation(state)
    const defaultLink = getDefaultLink(account.role)

    return redirect(defaultLink)
  }

  return null
}
