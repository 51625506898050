import { IBrand } from '@interfaces/entities/brand/brand'
import { Brand } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { v4 } from 'uuid'

export function brandSerializer(brand: Brand): IBrand {
  return {
    id: v4(),
    ...brand,
  }
}
