import { IProductAsset } from '@interfaces/entities/productAsset/productAsset'
import { buildUrl } from 'cloudinary-build-url'
import { v4 } from 'uuid'

export function productAssetSerializer(
  productAsset: IProductAsset,
): IProductAsset {
  const {
    Asset: { caption, metadata, folder, publicId, cloudName },
    position,
  } = productAsset

  const _metadata = metadata as { width: number; height: number }

  const _caption = caption && caption.length > 0 ? { caption } : {}
  const _width = _metadata && _metadata.width ? { width: _metadata.width } : {}
  const _height =
    _metadata && _metadata.height ? { height: _metadata.height } : {}

  return {
    position,
    thumbnail: buildUrl(`${folder}/${publicId}`, {
      cloud: {
        cloudName,
        secure: false,
        resourceType: 'image',
        storageType: 'upload',
      },
      transformations: {
        resize: {
          type: 'scale',
          width: 150,
          height: 150,
        },
      },
    }),
    url: buildUrl(`${folder}/${publicId}`, {
      cloud: {
        cloudName,
        secure: false,
        resourceType: 'image',
        storageType: 'upload',
      },
      transformations: {},
    }),
    ..._caption,
    ..._width,
    ..._height,
    id: v4(),
  } as IProductAsset
}
