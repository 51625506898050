import { shopAdapter } from '@adapters/shopAdapter'
import { Content } from '@components/Content/Content'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { RoutePaths } from '@interfaces/routePaths'
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Card,
  CardSection,
  Drawer,
  Flex,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { StatusEnum } from '@nabthisdev/nabthis-types'
import { useSetStatusMutation } from '@redux/shop/shopEndpoints'
import { selectShops, selectShopsIsLoading } from '@redux/shop/shopSlice'
import {
  IconCheck,
  IconEdit,
  IconExternalLink,
  IconInfoSmall,
  IconMapPin,
  IconShoppingCartOff,
  IconTrash,
  IconUsers,
  IconX,
} from '@tabler/icons-react'
import { buildLink } from '@utils/buildLink'
import { useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
// import { Users } from './Users'

function ShopTitle({
  shop,
  handleStatusClick,
}: {
  shop: Shop
  handleStatusClick: () => void
}): JSX.Element {
  const isActive = shop.status === StatusEnum.ACTIVE

  return (
    <>
      <Title order={5}>{shop.name}</Title>
      <Button
        size="xs"
        leftIcon={
          isActive ? (
            <IconCheck size="0.9rem" />
          ) : (
            <IconShoppingCartOff size="0.9rem" />
          )
        }
        onClick={handleStatusClick}
        variant="light"
        color={isActive ? 'green' : 'gray'}
      >
        {isActive ? 'Active' : 'Inactive'}
      </Button>
    </>
  )
}

export const Details = (): JSX.Element => {
  const { shopId = '' } = useParams()
  const navigate = useNavigate()
  const [isStatusOpen, setIsStatusOpen] = useState<boolean>(false)
  const [opened, { open, close }] = useDisclosure()
  const shopEntityState = useAppSelector(selectShops)
  const isLoading = useAppSelector(selectShopsIsLoading)
  const [setShopStatus, { isLoading: shopStatusLoading }] =
    useSetStatusMutation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const shop =
    shopEntityState &&
    !isLoading &&
    shopAdapter.getSelectors().selectById(shopEntityState, shopId)

  const handleManageNavigate = (mode: 'edit' | 'delete') =>
    navigate(`${RoutePaths.SHOPS_MANAGE}/${mode}`)

  const handleEditClick = () => handleManageNavigate('edit')
  const handleDeleteClick = () => handleManageNavigate('delete')
  const handleStatusChange = () => setIsStatusOpen(!isStatusOpen)

  const handleSetStatus = async () => {
    if (isSubmitting || !shop) return

    setIsSubmitting(true)

    try {
      const isActive = shop && shop.status === StatusEnum.ACTIVE
      await setShopStatus({ shopId, isActive }).unwrap()

      showNotification({
        message: `${shop.name} status updated!`,
        color: 'green',
      })
    } catch (e) {
      showNotification({
        message: `Unable to update ${shop.name} status!`,
        color: 'red',
      })
    } finally {
      setIsSubmitting(false)
      setIsStatusOpen(false)
    }
  }

  return (
    <>
      <Content>
        {shop ? (
          <>
            <Section
              title={
                <ShopTitle shop={shop} handleStatusClick={handleStatusChange} />
              }
            >
              <CardSection
                p="xs"
                sx={(theme) => ({
                  backgroundColor: theme.fn.lighten(theme.colors.gray[0], 0.3),
                })}
              >
                <Group position="apart">
                  <Button
                    variant="default"
                    leftIcon={<IconUsers size="0.9rem" />}
                    onClick={open}
                  >
                    Users
                  </Button>
                  <Group spacing="xs">
                    <Button
                      leftIcon={<IconEdit size="0.9rem" />}
                      onClick={handleEditClick}
                    >
                      Edit
                    </Button>
                    <Button
                      color="red"
                      leftIcon={<IconTrash size="0.9rem" />}
                      onClick={handleDeleteClick}
                    >
                      Delete
                    </Button>
                  </Group>
                </Group>
              </CardSection>
              <CardSection p="md" withBorder>
                <Flex>
                  <div>
                    <IconInfoSmall size="1.8rem" />
                  </div>
                  <Text ml="sm" color="gray.7" my={5} italic span>
                    {shop.description}
                  </Text>
                </Flex>
              </CardSection>
              <CardSection p="md" withBorder>
                <Stack>
                  <Group align="flex-start">
                    <Group spacing="xs">
                      <IconMapPin size="1.5rem" />
                      <Title w={75} order={5} color="gray.7">
                        Address
                      </Title>
                    </Group>
                    <div>
                      <Text>
                        {shop.addressLine1},
                        {shop.addressLine2 && (
                          <Text span> {shop.addressLine2},</Text>
                        )}
                      </Text>
                      <Text>
                        {shop.city}, {shop.state} {shop.zipcode}
                      </Text>
                    </div>
                  </Group>
                  <Group>
                    <Group spacing="xs">
                      <IconExternalLink size="1.5rem" />
                      <Title w={75} order={5} color="gray.7">
                        Website
                      </Title>
                    </Group>

                    <Anchor href={buildLink(shop.website)} target="_blank">
                      {shop.website}
                    </Anchor>
                  </Group>
                </Stack>
              </CardSection>
            </Section>
            <DestructiveConfirm
              confirmText={
                shop.status === StatusEnum.ACTIVE
                  ? 'Set Inactive'
                  : 'Set Active'
              }
              opened={isStatusOpen}
              onCancel={handleStatusChange}
              onConfirm={handleSetStatus}
              isLoading={shopStatusLoading}
            >
              <Text>
                Are you sure you would like to mark this seller as{' '}
                <strong>
                  {shop?.status === StatusEnum.ACTIVE ? 'Active' : 'Inactive'}
                </strong>
                ?
              </Text>
            </DestructiveConfirm>
          </>
        ) : (
          <>
            {!isLoading && <Card withBorder>Unable to find shop profile</Card>}
          </>
        )}
      </Content>

      <Drawer
        opened={opened}
        onClose={close}
        overlayProps={{ opacity: 0.1, blur: 3 }}
        withCloseButton={false}
        styles={{
          header: {
            padding: 0,
          },
          content: {
            padding: 0,
          },
          body: {
            padding: 0,
          },
          title: {
            width: '100%',
          },
        }}
        title={
          <Box
            sx={(theme) => ({
              borderBottom: `solid 1px ${theme.colors.gray[2]}`,
            })}
          >
            <Group position="apart" p="md">
              <Title order={5}>Shop Users</Title>
              <ActionIcon
                size="lg"
                variant="default"
                color="gray.0"
                onClick={close}
              >
                <IconX size="1rem" />
              </ActionIcon>
            </Group>
          </Box>
        }
        position="right"
        size="xl"
      >
        Disabled...
        {/* <Users /> */}
      </Drawer>

      <Outlet />
    </>
  )
}
