import { IShop } from '@interfaces/entities/shop/shop'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'

export function shopSerializer(shop: IShop): IShop {
  const { ShopHour, ShopAccount, ShopPost, ...rest } = shop

  // const {
  //   id,
  //   name,
  //   description,
  //   phone,
  //   website,
  //   addressLine1,
  //   addressLine2,
  //   city,
  //   state,
  //   zipcode,
  //   status,
  // } = shop

  // const accounts = Account
  //   ? {
  //       users: sellerUsersAdapter.setAll(
  //         sellerUsersAdapter.getInitialState(),
  //         sellerUsers.map(sellerUserSerializer),
  //       ),
  //     }
  //   : {}

  return {
    ...rest,
  } as IShop
}
