import { accountAdapter } from '@adapters/accountAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { Account } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { apiSlice, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'

export const accountEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.ACCOUNT],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      changePassword: builder.mutation({
        query: (body) => ({
          url: EndpointPaths.ACCOUNT_CHANGE_PASSWORD,
          method: 'POST',
          body: { ...body },
        }),
      }),
      getAccounts: builder.query<EntityState<Account>, string | null>({
        query: (query: string) => ({
          url: `${EndpointPaths.SEARCH_ACCOUNTS}?query=${query}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: Account[] }) =>
          accountAdapter.setAll(
            accountAdapter.getInitialState(),
            response?.data,
          ),
      }),
      getAllAccounts: builder.query<EntityState<Account>, void>({
        query: () => ({
          url: `${EndpointPaths.LIST_USERS}`,
          method: 'GET',
        }),
        transformResponse: (response: { data: Account[] }) =>
          accountAdapter.setAll(
            accountAdapter.getInitialState(),
            response?.data,
          ),
        providesTags: (result: EntityState<Account> | undefined) =>
          providesList(result?.ids, ApiTagTypes.ACCOUNT),
      }),
    }),
  })

export const {
  useChangePasswordMutation,
  useGetAccountsQuery,
  useGetAllAccountsQuery,
} = accountEndpoints
