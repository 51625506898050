import { productAdapter } from '@adapters/productAdapter'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { useAppSelector } from '@hooks/redux'
import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { ProductTypeEnum } from '@nabthisdev/nabthis-types'
import {
  useDeleteProductMutation,
  useGetProductsQuery,
} from '@redux/product/productEndpoints'
import { selectActiveShop } from '@redux/site/siteSlice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

export const DeleteProduct = (): JSX.Element => {
  const navigate = useNavigate()
  const { productId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [deleteProduct] = useDeleteProductMutation()
  const shop = useAppSelector(selectActiveShop)
  const [searchParams] = useSearchParams()
  const type = searchParams.get('type') ?? ProductTypeEnum.THRIFT

  const { data: productEntityState } = useGetProductsQuery(
    shop && shop.id
      ? {
          shopId: shop.id,
          type,
        }
      : skipToken,
  )

  const product =
    productEntityState &&
    productAdapter.getSelectors().selectById(productEntityState, productId)

  const handleCancel = () => navigate(`..`)
  const handleClose = () => navigate(`../../${type}`)

  const handleDelete = async () => {
    if (isSubmitting || !productId) return

    setSubmitting(true)

    try {
      await deleteProduct(productId).unwrap()

      showNotification({
        message: `${product?.title} deleted!`,
        color: 'green',
      })
    } catch (err) {
      showNotification({
        message: `Unable to delete ${product?.title}!`,
        color: 'red',
      })
    } finally {
      handleClose()
    }
  }

  return (
    <DestructiveConfirm
      confirmText="Delete"
      opened={true}
      onCancel={handleCancel}
      onConfirm={handleDelete}
      isLoading={isSubmitting}
    >
      <Text>
        Are you sure you would like to delete the product{' '}
        <strong>{product?.title}</strong>?
      </Text>
    </DestructiveConfirm>
  )
}
