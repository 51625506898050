import { Content } from '@components/Content/Content'
import { Section } from '@components/Section/Section'
import { useAppSelector } from '@hooks/redux'
import { useSetSection } from '@hooks/useSetSection'
import { CardSection, Group, List, Text } from '@mantine/core'
import { selectAccountInformation } from '@redux/account/accountSlice'

export const Overview = (): JSX.Element => {
  useSetSection('Admin Overview')

  const account = useAppSelector(selectAccountInformation)

  return (
    <Content>
      <Section title="Welcome">
        <CardSection p="md" pt={0}>
          <List icon={<></>}>
            <List.Item>
              <Group>
                <Text w={100} size="md" fw="bold">
                  ID
                </Text>
                <Text size="md">{account.id}</Text>
              </Group>
            </List.Item>
            <List.Item>
              <Group>
                <Text w={100} size="md" fw="bold">
                  Email
                </Text>
                <Text size="md">{account.email}</Text>
              </Group>
            </List.Item>
            <List.Item>
              <Group>
                <Text w={100} size="md" fw="bold">
                  Role
                </Text>
                <Text size="md">{account.role}</Text>
              </Group>
            </List.Item>
          </List>
        </CardSection>
      </Section>
    </Content>
  )
}
