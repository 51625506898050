import { shopAdapter } from '@adapters/shopAdapter'
import { FormError } from '@components/FormError/FormError'
import { Modal } from '@components/Modal/Modal'
import { useAppSelector } from '@hooks/redux'
import { MutationError } from '@interfaces/mutationError'
import { Button, Group, Stack, TextInput } from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { useEditShopMutation } from '@redux/shop/shopEndpoints'
import { selectShops } from '@redux/shop/shopSlice'
import {
  editShopInitialState,
  editShopSchema,
  EditShopState,
} from '@schemas/shop/editShop'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const EditShop = (): JSX.Element => {
  const navigate = useNavigate()
  const { shopId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()
  const [editShop, { isSuccess }] = useEditShopMutation()
  const shopEntityState = useAppSelector(selectShops)

  const shop =
    shopEntityState &&
    (shopAdapter.getSelectors().selectById(shopEntityState, shopId) as Shop)

  const { onSubmit, isDirty, getInputProps, setFieldValue } = useForm({
    initialValues: editShopInitialState(shop as EditShopState),
    validate: yupResolver(editShopSchema),
  })

  const handleClose = () => navigate('..')

  const handleSubmit = async (values: EditShopState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      await editShop({ shopId, ...values }).unwrap()

      showNotification({
        message: `${shop?.name} edited!`,
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    setFieldValue('name', shop?.name)
    setFieldValue('address', shop?.address)
    setFieldValue('website', shop?.website)
    setFieldValue('description', shop?.description)
  }, [shop])

  return (
    <Modal title="Edit Shop Profile" opened={true} onClose={handleClose}>
      <form
        onSubmit={onSubmit((values: EditShopState) => handleSubmit(values))}
      >
        <Stack>
          {!isSuccess && formError && <FormError error={formError} />}

          <TextInput
            label="Name"
            placeholder="Name"
            data-autofocus
            {...getInputProps('name')}
          />
          <Stack spacing="xs">
            <TextInput
              label="Address"
              placeholder="Address"
              {...getInputProps('addressLine1')}
            />
            <TextInput
              placeholder="Address 2 (Optional)"
              {...getInputProps('addressLine2')}
            />
            <TextInput placeholder="City" {...getInputProps('city')} />
            <TextInput placeholder="State" {...getInputProps('state')} />
            <TextInput placeholder="Zipcode" {...getInputProps('zipcode')} />
          </Stack>

          <TextInput
            label="Website"
            placeholder="Website"
            {...getInputProps('website')}
          />
          <TextInput
            label="Description"
            placeholder="Description"
            {...getInputProps('description')}
          />

          <Group position="right">
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Edit
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
