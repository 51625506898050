import { FormError } from '@components/FormError/FormError'
import { Modal } from '@components/Modal/Modal'
import { MutationError } from '@interfaces/mutationError'
import {
  Anchor,
  Button,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core'
import { useForm, yupResolver } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { ShopHourDayEnum, ShopHourHourEnum } from '@nabthisdev/nabthis-types'
import { useCreateShopMutation } from '@redux/shop/shopEndpoints'
import {
  createShopInitialState,
  createShopSchema,
  CreateShopState,
} from '@schemas/shop/createShop'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const CreateShop = (): JSX.Element => {
  const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = useState(false)
  const [formError, setFormError] = useState<MutationError | null>()

  const [createShop, { isSuccess }] = useCreateShopMutation()

  const {
    onSubmit,
    isDirty,
    insertListItem,
    removeListItem,
    getInputProps,
    values,
  } = useForm({
    initialValues: createShopInitialState(),
    validate: yupResolver(createShopSchema),
  })

  const handleClose = () => navigate('..')

  const handleSubmit = async (values: CreateShopState) => {
    if (isSubmitting) return

    setSubmitting(true)
    setFormError(null)

    try {
      const _filterHours =
        values.hours && values.hours.length > 0
          ? values.hours.filter(
              (curr) =>
                curr.day !== '' &&
                curr.day !== null &&
                curr.open !== '' &&
                curr.open !== null &&
                curr.close !== '' &&
                curr.close !== null,
            )
          : []

      const _hours = _filterHours.length > 0 ? { hours: _filterHours } : {}

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { hours = [], ...rest } = values

      const _values = {
        ...rest,
        ..._hours,
      }

      await createShop(_values).unwrap()

      showNotification({
        message: 'Shop Profile Created!',
        color: 'green',
      })

      handleClose()
    } catch (err) {
      setFormError(err)
    } finally {
      setSubmitting(false)
    }
  }

  const DAYS_DATA = Object.values(ShopHourDayEnum).map((curr) => ({
    label: curr,
    value: curr,
  }))

  const TIMES_DATA = Object.values(ShopHourHourEnum).map(
    (curr: ShopHourHourEnum) => ({ label: curr, value: curr }),
  )

  const removeHourItem = (index: number) => {
    if (values.hours && values.hours.length > 1) removeListItem('hours', index)
  }

  const hourFields =
    values.hours &&
    values.hours.map((_, index) => (
      <Stack key={`hours-${index}`} spacing={2}>
        <Group>
          <Group grow align="center">
            <Select
              label={'Day'}
              data={DAYS_DATA}
              defaultValue={DAYS_DATA[0].value}
              {...getInputProps(`hours.${index}.day`)}
            />
            <Select
              label={'Open'}
              data={TIMES_DATA}
              defaultValue={TIMES_DATA[8].value}
              {...getInputProps(`hours.${index}.open`)}
            />
            <Select
              label={'Close'}
              data={TIMES_DATA}
              defaultValue={TIMES_DATA[8].value}
              {...getInputProps(`hours.${index}.close`)}
            />
          </Group>
        </Group>
        {index !== 0 && (
          <Anchor size="xs" c="red" onClick={() => removeHourItem(index)}>
            Remove
          </Anchor>
        )}
      </Stack>
    ))

  const addHourItem = () => {
    insertListItem('hours', {
      day: '',
      open: '',
      close: '',
    })
  }

  return (
    <Modal title="New Shop Profile" opened={true} onClose={handleClose}>
      <form
        onSubmit={onSubmit((values: CreateShopState) => handleSubmit(values))}
      >
        <Stack>
          {!isSuccess && formError && <FormError error={formError} />}

          <TextInput
            label="Name"
            placeholder="Name"
            data-autofocus
            withAsterisk
            {...getInputProps('name')}
          />
          <Stack spacing="xs">
            <TextInput
              label="Address"
              placeholder="Address"
              withAsterisk
              {...getInputProps('addressLine1')}
            />
            <TextInput
              placeholder="Address 2 (Optional)"
              {...getInputProps('addressLine2')}
            />
            <TextInput
              placeholder="City"
              withAsterisk
              {...getInputProps('city')}
            />
            <TextInput
              placeholder="State"
              withAsterisk
              {...getInputProps('state')}
            />
            <TextInput
              placeholder="Zipcode"
              withAsterisk
              {...getInputProps('zipcode')}
            />
          </Stack>
          <TextInput
            placeholder="Website"
            withAsterisk
            {...getInputProps('website')}
          />
          <TextInput
            placeholder="Description"
            withAsterisk
            {...getInputProps('description')}
          />

          <Stack spacing={2}>
            <Title order={6}>Hours</Title>
            <Text size="xs" c="dimmed">
              Optional
            </Text>
          </Stack>

          <Stack>{hourFields}</Stack>

          <Anchor onClick={addHourItem} size="sm">
            + Add Hour
          </Anchor>
          <Group position="right">
            <Button variant="default" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" loading={isDirty() && isSubmitting}>
              Create
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}
