import { accountAdapter } from '@adapters/accountAdapter'
import { Account, Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import type { RootState } from '@redux/store'
import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface SiteState {
  activeSection: string
  activeShop: Shop | null
}

const initialState: SiteState = {
  activeSection: '',
  activeShop: null,
}

export const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setActiveSection: (state, action: PayloadAction<{ section: string }>) => {
      state.activeSection = action.payload.section
    },
    setActiveShop: (state, action: PayloadAction<{ shop: any }>) => {
      const { shop } = action.payload

      state.activeShop = {
        ...shop,
      }
    },
    clearSiteInformation: (state: SiteState) => {
      state = initialState

      return state
    },
  },
})

export const { setActiveSection, setActiveShop, clearSiteInformation } =
  siteSlice.actions

export const selectActiveShop = (state: RootState) => state.site.activeShop
export const selectActiveSection = (state: RootState) =>
  state.site.activeSection
