import { productAssetAdapter } from '@adapters/productAssetAdapter'
import { IProduct } from '@interfaces/entities/product/product'
import { v4 } from 'uuid'
import { productAssetSerializer } from './productAssetSerializer'

export function productSerializer(product: IProduct): IProduct {
  const { ProductAsset = [], ..._product } = product

  const _productAsset = productAssetAdapter.setAll(
    productAssetAdapter.getInitialState(),
    ProductAsset?.map(productAssetSerializer),
  )

  return {
    ..._product,
    id: v4(),
    assets: _productAsset,
  } as IProduct
}
