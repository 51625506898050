import { useParams } from 'react-router-dom'
import { CreateShop } from './CreateShop'
import { DeleteShop } from './DeleteShop'
import { EditShop } from './EditShop'

export const Manage = (): JSX.Element => {
  const { mode } = useParams()

  return (
    <>
      {!mode && <CreateShop />}
      {mode === 'edit' && <EditShop />}
      {mode === 'delete' && <DeleteShop />}
    </>
  )
}
