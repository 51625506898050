import { shopAdapter } from '@adapters/shopAdapter'
import { DestructiveConfirm } from '@components/DestructiveConfirm/DestructiveConfirm'
import { useAppSelector } from '@hooks/redux'
import { RoutePaths } from '@interfaces/routePaths'
import { Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { useDeleteShopMutation } from '@redux/shop/shopEndpoints'
import { selectShops } from '@redux/shop/shopSlice'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const DeleteShop = (): JSX.Element => {
  const navigate = useNavigate()
  const { shopId = '' } = useParams()
  const [isSubmitting, setSubmitting] = useState(false)
  const [deleteShop] = useDeleteShopMutation()
  const shopEntityState = useAppSelector(selectShops)

  const shop =
    shopEntityState &&
    (shopAdapter.getSelectors().selectById(shopEntityState, shopId) as Shop)

  const handleClose = () => navigate(`../../${RoutePaths.SHOPS_LIST}`)

  const handleDelete = async () => {
    if (isSubmitting || !shopId) return

    setSubmitting(true)

    try {
      await deleteShop(shopId).unwrap()

      showNotification({
        message: `${shop.name} deleted!`,
        color: 'green',
      })
    } catch (err) {
      showNotification({
        message: `Unable to delete ${shop.name}!`,
        color: 'red',
      })
    } finally {
      handleClose()
    }
  }

  return (
    <DestructiveConfirm
      confirmText="Delete"
      opened={true}
      onCancel={handleClose}
      onConfirm={handleDelete}
      isLoading={isSubmitting}
    >
      <Text>
        Are you sure you would like to delete the shop{' '}
        <strong>{shop?.name}</strong>?
      </Text>
    </DestructiveConfirm>
  )
}
