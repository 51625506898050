import { brandAdapter } from '@adapters/brandAdapter'
import { EndpointPaths } from '@interfaces/endpointPaths'
import { IBrand } from '@interfaces/entities/brand/brand'
import { Brand } from '@nabthisdev/nabthis-orm/dist/generated/client'
import { apiSlice, invalidatesList, providesList } from '@redux/apiSlice'
import { ApiTagTypes } from '@redux/apiTagTypes'
import { EntityState } from '@reduxjs/toolkit'
import { brandSerializer } from '@serializers/brandSerializer'

export const brandEndpoints = apiSlice
  .enhanceEndpoints({
    addTagTypes: [ApiTagTypes.BRAND],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBrands: builder.query<EntityState<IBrand>, void>({
        query: () => ({
          url: EndpointPaths.BRAND,
          method: 'GET',
        }),
        transformResponse: (response: { data: Brand[] }) =>
          brandAdapter.setAll(
            brandAdapter.getInitialState(),
            response?.data.map(brandSerializer),
          ),
        providesTags: (result: EntityState<IBrand> | undefined) =>
          providesList(result?.ids, ApiTagTypes.BRAND),
      }),
      createBrand: builder.mutation({
        query: (body) => ({
          url: EndpointPaths.BRAND,
          method: 'POST',
          body: { ...body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRAND)],
      }),
      editBrand: builder.mutation({
        query: ({ brandName, ...body }) => ({
          url: `${EndpointPaths.BRAND}/${brandName}`,
          method: 'PATCH',
          body: { ...body },
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRAND)],
      }),
      deleteBrand: builder.mutation({
        query: (brandName) => ({
          url: `${EndpointPaths.BRAND}/${brandName}`,
          method: 'DELETE',
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRAND)],
      }),
      setBrandStatus: builder.mutation({
        query: ({ brandName, isActive }) => ({
          url: isActive
            ? `${EndpointPaths.BRANDS_DISABLE}/${brandName}`
            : `${EndpointPaths.BRANDS_ENABLE}/${brandName}`,
          method: 'POST',
        }),
        invalidatesTags: [invalidatesList(ApiTagTypes.BRAND)],
      }),
    }),
  })

export const {
  useGetBrandsQuery,
  useCreateBrandMutation,
  useEditBrandMutation,
  useDeleteBrandMutation,
  useSetBrandStatusMutation,
} = brandEndpoints
