import { CACHE_PREFETCH } from '@constants/cache'
import { shopEndpoints } from '@redux/shop/shopEndpoints'
import { store } from '@redux/store'

export function accountShopsPrefetch() {
  store.dispatch(
    shopEndpoints.util.prefetch('getAccountShops', undefined, {
      ifOlderThan: CACHE_PREFETCH,
    }),
  )
}
