import { useAppDispatch } from '@hooks/redux'
import { useSetSection } from '@hooks/useSetSection'
import { useGetAccountShopsQuery } from '@redux/shop/shopEndpoints'
import { setShops } from '@redux/shop/shopSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export const Base = (): JSX.Element => {
  useSetSection('Shops')

  const dispatch = useAppDispatch()

  const { data: shopEntityState, isLoading, error } = useGetAccountShopsQuery()

  useEffect(() => {
    dispatch(setShops({ shops: shopEntityState, isLoading, error }))
  }, [shopEntityState, isLoading, error])

  return <Outlet />
}
