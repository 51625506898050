import { Account } from '@nabthisdev/nabthis-orm/dist/generated/client'
import {
  AccountRoleEnum,
  AccountTypeEnum,
  StatusEnum,
} from '@nabthisdev/nabthis-types'
import type { RootState } from '@redux/store'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getFullName } from '@utils/getFullName'

interface AccountState
  extends Omit<Account, 'createdAt' | 'updatedAt' | 'username' | 'phone'> {
  name: string
}

const initialState: AccountState = {
  id: '',
  type: AccountTypeEnum.SHOP,
  email: '',
  name: '',
  firstName: '',
  lastName: '',
  middleName: '',
  birthdate: '',
  pronouns: '',
  role: AccountRoleEnum.ADMIN,
  status: StatusEnum.ACTIVE,
  isEnabled: true,
  isConfirmed: true,
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountInformation: (state, action: PayloadAction<AccountState>) => {
      const {
        id,
        firstName,
        lastName,
        middleName,
        email,
        birthdate,
        pronouns,
        role,
        status,
        isEnabled,
        isConfirmed,
      } = action.payload
      state.id = id
      state.firstName = firstName
      state.lastName = lastName
      state.middleName = middleName
      state.email = email
      state.birthdate = birthdate
      state.pronouns = pronouns
      state.role = role
      state.status = status
      state.isEnabled = isEnabled
      state.isConfirmed = isConfirmed
      state.name = getFullName(firstName, lastName, middleName)
    },
    clearAccountInformation: (state: AccountState) => {
      state = initialState

      return state
    },
  },
})

export const { setAccountInformation, clearAccountInformation } =
  accountSlice.actions

export const selectAccountInformation = (state: RootState) => state.account
