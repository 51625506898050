import { shopAdapter } from '@adapters/shopAdapter'
import { Shop } from '@nabthisdev/nabthis-orm/dist/generated/client'
import type { RootState } from '@redux/store'
import type { EntityState, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface ShopState {
  shops: EntityState<Shop> | undefined
  isLoading: boolean
  error: unknown
}

const initialState: ShopState = {
  shops: shopAdapter.setAll(shopAdapter.getInitialState(), []),
  isLoading: false,
  error: null,
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setShops: (state, action: PayloadAction<ShopState>) => {
      const { shops, isLoading, error } = action.payload

      state.shops = shops
      state.isLoading = isLoading
      state.error = error
    },
  },
})

export const { setShops } = shopSlice.actions

export const selectShops = (state: RootState) => state.shop.shops
export const selectShopsIsLoading = (state: RootState) => state.shop.isLoading
export const selectShopsError = (state: RootState) => state.shop.error
