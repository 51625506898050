import { authSlice } from '@redux//auth/authSlice'
import { apiSlice } from '@redux/apiSlice'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { accountSlice } from './account/accountSlice'
import { shopSlice } from './shop/shopSlice'
import { siteSlice } from './site/siteSlice'

export const store = configureStore({
  reducer: persistReducer(
    {
      key: 'root',
      storage,
      whitelist: ['auth', 'account', 'site'],
      blacklist: [apiSlice.reducerPath],
    },
    combineReducers({
      [apiSlice.reducerPath]: apiSlice.reducer,
      auth: authSlice.reducer,
      account: accountSlice.reducer,
      site: siteSlice.reducer,
      shop: shopSlice.reducer,
    }),
  ),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      apiSlice.middleware,
    ),
  devTools: !import.meta.env.PROD,
})

export const persistor = persistStore(store)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
