export function getFullName(
  firstName: string,
  lastName: string,
  middleName?: string | undefined | null,
) {
  if (
    !middleName ||
    middleName == null ||
    (typeof middleName === 'string' && middleName.length === 0)
  ) {
    return `${firstName} ${lastName}`
  } else {
    return `${firstName} ${middleName} ${lastName}`
  }
}
