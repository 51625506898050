import * as Yup from 'yup'

export const editShopSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  addressLine1: Yup.string().required('Address is required'),
  addressLine2: Yup.string().optional(),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipcode: Yup.string().required('Zipcode is required'),
  website: Yup.string().required('Website is required'),
  description: Yup.string().required('Description is required'),
  hours: Yup.array()
    .of(
      Yup.object({
        day: Yup.string(),
        open: Yup.string(),
        close: Yup.string(),
      }),
    )
    .optional(),
})

export type EditShopState = Yup.InferType<typeof editShopSchema>

export const editShopInitialState: (
  defaults: Partial<EditShopState>,
) => EditShopState = (defaults: Partial<EditShopState>) => {
  return {
    name: defaults?.name ?? '',
    addressLine1: defaults?.addressLine1 ?? '',
    addressLine2: defaults?.addressLine2 ?? '',
    city: defaults?.city ?? '',
    state: defaults?.state ?? '',
    zipcode: defaults?.zipcode ?? '',
    website: defaults?.website ?? '',
    description: defaults?.description ?? '',
    hours: defaults?.hours ?? [],
  }
}
