import { accountAdapter } from '@adapters/accountAdapter'
import { EmptyState } from '@components/EmptyState/EmptyState'
import { Section } from '@components/Section/Section'
import { IAccount } from '@interfaces/entities/account/account'
import { RoutePaths } from '@interfaces/routePaths'
import {
  Badge,
  CardSection,
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { EntityState } from '@reduxjs/toolkit'
import { IconShoppingBag } from '@tabler/icons-react'
import { getFullName } from '@utils/getFullName'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'

export const List = () => {
  const navigate = useNavigate()
  const {
    userEntityState,
    isLoading,
    error,
  }: {
    userEntityState: EntityState<IAccount>
    isLoading: boolean
    error: unknown
  } = useOutletContext()

  const users = userEntityState
    ? accountAdapter.getSelectors().selectAll(userEntityState)
    : []

  const handleClick = (userId: string) =>
    navigate(`../${RoutePaths.ADMIN_ACCOUNTS_DETAILS}/${userId}`)

  return (
    <>
      {userEntityState && !isLoading && !error && (
        <>
          {users.length > 0 ? (
            <Section
              title={
                <Title order={5}>
                  Platform Users{' '}
                  <Text color="gray.6" fw="normal" span>
                    ({users.length})
                  </Text>
                </Title>
              }
            >
              {users.map((user: any, i: number) => (
                <CardSection
                  key={user.id}
                  p="md"
                  onClick={() => handleClick(user.id)}
                  withBorder={i !== 0}
                  sx={(theme) => ({
                    cursor: 'pointer',
                    '&:hover': {
                      '[data-user]': {
                        color: theme.colors.violet,
                      },
                    },
                  })}
                >
                  <Stack>
                    <Group position="apart">
                      <Text fw="bold" size="sm" color="gray.7" data-user>
                        {getFullName(
                          user.firstName,
                          user.lastName,
                          user.middleName,
                        )}
                      </Text>
                      {/* <Group>
                        {user.sellerUsers && (
                          <>
                            {user.sellerUsers.length > 0 ? (
                              <>
                                <ThemeIcon
                                  variant="light"
                                  color="pink"
                                  radius="xl"
                                >
                                  <IconShoppingBag size="0.9rem" />
                                </ThemeIcon>
                              </>
                            ) : null}
                          </>
                        )}
                        {user.isActive ? (
                          <Badge color="green">Active</Badge>
                        ) : (
                          <Badge color="gray">Inactive</Badge>
                        )}
                      </Group> */}
                    </Group>
                    <Text color="gray.7" size="xs">
                      {user.email}
                    </Text>
                  </Stack>
                </CardSection>
              ))}
            </Section>
          ) : (
            <>
              <EmptyState type="users" />
            </>
          )}
        </>
      )}

      <Outlet context={{ userEntityState, isLoading, error }} />
    </>
  )
}
